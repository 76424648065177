import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Config } from '../../vir-config';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
@Injectable(
    {
        providedIn: 'root'
    }
)
export class sidebarService implements Resolve<any>
{
    constructor(private http: HttpClient) { }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    }
    getusergrouppages() {
        var Id = JSON.parse(localStorage.currentUser).GroupId;
        const url = Config.apiurl + '/grouppermission/' + Id;
        return new Promise((resolve, reject) => {
            this.http.get(url)
                .subscribe((data: any) => {
                    resolve(data);
                }, reject);

        });
    }

}


