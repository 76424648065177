import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NotificationComponent } from './notification.component';
@NgModule({
	imports: [
		CommonModule,
		HttpClientModule
	],
	// declarations: [NotificationComponent],
	// exports:[NotificationComponent],
	// entryComponents: [NotificationComponent],
})
export class NotificationModule { }

