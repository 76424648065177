//#region Import
// common services to share data between two unrelated components
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
//#endregion
@Injectable({ providedIn: 'root' })
export class DataService {
  //#region Declaration
  private userrights = new BehaviorSubject('');
  storeduserrights = this.userrights.asObservable();
  private notification = new BehaviorSubject('');
  notificationlist = this.notification.asObservable();
  private timeout: any;
  //#endregion
  constructor(readonly zone: NgZone) { }
  //#region set/get query string
  Passuserrights(Query: string) {
    this.userrights.next(Query);
  }

  UpdateNotification(obj) {

    this.notification.next(null);
    this.zone.run(() => {
      this.notification.next(obj);
      //this.timeout = setTimeout(() => this.notification.next(null), 3000);
    });

  }

  //#endregion
}