
export const Config = {
    // apiurl: 'http://localhost:4000',//http://localhost:4000',
    // companyLogoUrl: 'http://localhost:4000/Company'
    apiurl: 'https://beststarcargo.com/sfn-cargo-billing-api',
    companyLogoUrl: 'https://beststarcargo.com/sfn-cargo-billing-api/Company'
};

export const layoutConfig = { 
    customScrollbars: true, 
};