import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
    username: string;
    message: string;
    type: string;
    FromDate: Date;
    ToDate: Date;
}
@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
})
export class DialogBarComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogBarComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
