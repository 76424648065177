import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
// import { DropZoneDirective } from '../../directives/drop-zone.directive';
import { File } from '../../dto/file';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/switchMap';

@Component({
    selector: 'input-file',
    styleUrls: ['input-file.component.scss'],
    templateUrl: 'input-file.component.html'
})

export class InputFileComponent implements AfterViewInit {
    
    // #region public attributes
    // #region input
    @Input() public inputId: string;
    @Input() public inputAccept: string;
    @Input() public disableUpload = false;
    @Input() public inputMaxFiles = 1;
    @Input() public minimal = false;
    @Input() public model: Array<File> = new Array<File>();
    @Input() public textBrowse = 'Browse';
    @Input() public textFileSelected = 'files selected';
    @Input() public textNoFile = 'No file selected';
    @Input() public textRemove = 'Remove';
    @Input() public textUpload = 'Upload';
    // #endregion

    // #region output
    @Output() public limitReached: EventEmitter<any> = new EventEmitter<any>();
    @Output() public acceptedFile: EventEmitter<File> = new EventEmitter<File>();
    @Output() public rejectedFile: EventEmitter<File> = new EventEmitter<File>();
    @Output() public removedFile: EventEmitter<File> = new EventEmitter<File>();
    @Output() public uploadFiles: EventEmitter<any> = new EventEmitter<any>();
    // #endregion

    // #region view child
    @ViewChild('inputFile',null) public inputFile: any;
    // #region view child

    public dropZoneDisabled = false;
    public isDragOver = false;
    public isInputActive = false;
    // #endregion
    // #region public attributes
    private iconExtension = '.png';
    private pathIcon = 'assets/img/';
    private typeImage = 'image/';
    // #endregion

    public canvasImage: any;
    // #region public functions
    // #region events

    /**
     * Blur event handler.
     */
    public onBlur(): void {
        this.isInputActive = false;
    }

    /**
     * Change event handler.
     * @param event
     */
    public onChange(event: any): void {
        this.addInputFile(event.target.files);
    }

    /**
     * Focus event handler
     */
    public onFocus(): void {
        this.isInputActive = true;
    }

    /**
     * File Drag Over event handler.
     */
    public onFileDragOver(): void {
        this.isDragOver = true;
    }

    /**
     * File Drag Leave event handler.
     */
    public onFileDragLeave(): void {
        this.isDragOver = false;
    }

    /**
     * File Drop event handler.
     * @param files.
     */
    public onFileDrop(files: any): void {
        this.isDragOver = false;
        this.addInputFile(files);
    }

    /**
     * Remove event handler.
     * Removes all files of the model.
     */
    public onRemove(): void {
        for (const file of this.model) {
            this.removedFileHandler(file);
        }
        this.removeHandler();
    }

    /**
     * Remove file event handler.
     * @param index
     */
    public onRemoveFile(index: number): void {
        this.removedFileHandler(this.model[index]);
        this.model.splice(index, 1);
        this.dropZoneDisabled = false;
        if (!this.model.length) {
            this.removeHandler();
        }
    }

    /**
     * Upload event handler;
     */
    public onUpload(): void {
        this.uploadFiles.emit();
    }

    // #endregion

    // #region functions

    /**
     * Gets the text of the input.
     */
    public getInputText(): string {
        let inputText: string;

        if (this.model == null || !this.model.length) {
            inputText = this.textNoFile;
        } else if (this.model.length < 2 && this.model[0].file != null) {
            inputText = this.model[0].file.name;
        } else {
            inputText = this.model.length + ' ' + this.textFileSelected;
        }

        return inputText;
    }

    /**
     * Checks if the model is null or empty.
     */
    public isNotNullOrEmpty(): boolean {
        return this.model != null && this.model.length != null && this.model.length > 0;
    }

    // #endregion
    // #endregion

    // #region private functions

    /**
     * Accepted file handler.
     * @param file
     */
    private acceptedFileHandler(file: File): void {
        this.acceptedFile.emit(file);
    }

    /**
     * Adds multiple files to the model.
     * @param files
     */
    private addInputFile(files: Array<any>): void {
        const limit: number = +this.inputMaxFiles;

        for (let index = 0; index < files.length; index++) {
            // Checks the limit
            if (this.model.length < limit) {
                // Checks the type
                if (this.isTypeEnabled(files[index])) {
                    const file = new File();
                    this.setSize(file, files[index]);
                    // Checks the icon
                    if (FileReader && this.isTypeImage(files[index])) {
                        this.readFileHandler(file, files[index]);
                    } else {
                        this.setFileIcon(file, files[index]);
                    }
                    file.file = files[index];
                    this.model.push(file);
                    this.acceptedFileHandler(file);
                } else {
                    this.rejectedFileHandler(files[index]);
                }
            } else {
                // Limit reached
                break;
            }
        }

        if (this.model.length >= limit && !this.minimal) {
            this.limitReachedHandler();
        }
    }

    /**
     * Checks if the file is an image.
     * @param file
     */
    private isTypeImage(file: any): boolean {
        return file.type.startsWith(this.typeImage);
    }

    /**
     * Checks if the type of the file is enabled.
     * @param file
     */
    private isTypeEnabled(file: any): boolean {
        let enabled = this.inputAccept == null;
        if (this.inputAccept) {
            const accept = this.inputAccept.replace('*', '');
            const types = accept.split(',');
            for (const type of types) {
                if (file.type.startsWith(type) || (type.charAt(0) === '.' && file.name != null && file.name.endsWith(type))) {
                    enabled = true;
                    break;
                }
            }
        }

        return enabled;
    }

    /**
     * Limit reached handler.
     */
    private limitReachedHandler(): void {
        this.dropZoneDisabled = true;
        this.limitReached.emit();
    }

    /**
     * Read file handler.
     * @param image.
     */
    private readFileHandler(file: File, image: any): void {
        const fr = new FileReader();

        fr.onload = function () {
            file.icon = fr.result.toString();
        };

        fr.readAsDataURL(image);
    }

    /**
     * Rejected file handler.
     * @param file
     */
    private rejectedFileHandler(file: File): void {
        this.rejectedFile.emit(file);
    }

    /**
     * Removed file handler.
     * @param file
     */
    private removedFileHandler(file: File): void {
        this.removedFile.emit(file);
    }

    /**
     * Remove handler.
     */
    private removeHandler(): void {
        this.dropZoneDisabled = false;
        this.inputFile.nativeElement.value = '';
        this.model.splice(0, this.model.length);
    }

    /**
     * Sets the icon of the file.
     * @param file
     * @param inputFile
     */
    private setFileIcon(file: File, inputFile: any): void {
        let icon: string;

        switch (inputFile.type) {
            case 'application/pdf':
                icon = 'pdf';
                break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                icon = 'doc';
                break;
            case 'application/zip':
                icon = 'zip';
                break;
            default:
                icon = 'file';
        }

        file.icon = this.pathIcon + icon + this.iconExtension;
    }

    /**
     * Gets the size of the file to display.
     * @param file
     */
    private setSize(file: File, inputFile: any): void {
        const size = Math.round(inputFile.size / 1024);
        file.size = '(' + size + ' KB)';
    }

    // #endregion

    // public getPicture(): void {
    //     console.log('Take Pic');
    //     (<any>window).navigator.camera.getPicture
    // }


    @ViewChild('layout',null) public canvas;
    canvasEl: HTMLCanvasElement;
    @ViewChild('layout',null) public canvasRef;
    @Input() public width = 250;
    @Input() public height = 600;

    private cx: CanvasRenderingContext2D;
    mousePressed = false;
    lastX; lastY;
    cPushArray = new Array();
    cStep = -1;
    camimageList = new Array();
    imageURI;
    public getPicture(isFolder) {
        (<any>window).navigator.camera.getPicture(
            (imageURI) => {
                imageURI = "data:image/jpeg;base64," + imageURI;
                this.captureEvents(imageURI);
            }, this.onFail, {
                //  destinationType: Camera.DestinationType.DATA_URL,
                //  sourceType: Camera.PictureSourceType.CAMERA,
                // quality: 100,
                // destinationType: Camera.DestinationType.DATA_URL,
                // sourceType: isFolder != undefined && (isFolder == true || isFolder == 'true') ? Camera.PictureSourceType.PHOTOLIBRARY : Camera.PictureSourceType.CAMERA,
                // encodingType: Camera.EncodingType.JPEG,
                // allowEdit: false,
                // targetWidth: $(window).width() - 5, targetHeight: $(window).height() - 5,
                // correctOrientation: true,
            });

    }
    private captureEvents(imageURI) {
        $('.draw-img').show();
        $('.topbar').hide();
        this.canvasImage = imageURI;
        console.log(this.canvasImage);
        this.canvasEl = this.canvas.nativeElement;
        this.cx = this.canvasEl.getContext('2d');

        let source = new Image();

        source.onload = () => {
            this.canvasEl.width = source.width;
            this.canvasEl.height = source.height;
            this.cx.lineWidth = 3;
            this.cx.lineCap = 'round';
            this.cx.strokeStyle = '#f44336';
            console.log(source.width);
            console.log(source.height);
            console.log(this.canvasEl.width);
            console.log(this.canvasEl.height);

            this.cx.drawImage(source, 0, 0, source.width, source.height, 0, 0, this.canvasEl.width, this.canvasEl.height);
            this.cPush();
        };

        source.src = imageURI;
        this.imageURI = imageURI;

        console.log('captureEvents');
    }
    cPush() {
        console.log('cPush');
        this.cStep++;
        if (this.cStep < this.cPushArray.length) { this.cPushArray.length = this.cStep; }
        this.cPushArray.push(this.canvasEl.toDataURL());
        document.title = this.cStep + ":" + this.cPushArray.length;
        console.log(this.cPushArray);
        console.log(this.cStep);
        console.log(this.canvasEl.toDataURL());
        console.log(this.cPushArray[0]);
    }
    Draw(x, y, isDown) {
        console.log(this.cx);

        if (isDown) {
            this.cx.beginPath();
            this.cx.lineJoin = "round";
            this.cx.moveTo(this.lastX, this.lastY);
            this.cx.lineTo(x, y);
            this.cx.closePath();
            this.cx.stroke();
        }
        this.lastX = x;
        this.lastY = y;
        console.log(x);
        console.log(y);
    }

    cUndo() {
        console.log('cUndo');
        console.log(this.cStep);
        console.log(this.cPushArray);
        if (this.cStep > 0) {
            this.cStep--;
            console.log(this.cStep);
            var canvasPic = new Image();
            canvasPic.src = this.cPushArray[this.cStep];
            console.log(this.cPushArray[this.cStep]);
            canvasPic.onload = () => { this.cx.drawImage(canvasPic, 0, 0); }
            document.title = this.cStep + ":" + this.cPushArray.length;
        }
    }
    onFail(message) {
        console.log('Failed because: ' + message);
    }
    public deleteUploadimg(index: number): void {
        this.camimageList.splice(index, 1);
        console.log('Deleted');
    }
    ngAfterViewInit() {
        $(() => {
            $('.save-draw-img').click(() => {
                this.SaveCanvas(this.canvasEl.toDataURL());
            })
            $('.back-draw-img').click(() => {
                //this.camimageList.push({ 'originalname': 'aa', 'blob': this.imageURI });
                this.captureEvents(this.imageURI);
                this.SaveCanvas(this.imageURI);
            })
            $('.undo-draw-img').click(() => {
                this.cUndo();
            })
            $('#canvas').on('touchstart', e => { //pointerenter
                console.log('touchstart');
                this.canvasEl = this.canvas.nativeElement;
                const rect = this.canvasEl.getBoundingClientRect();
                this.mousePressed = true;
                this.Draw(e.targetTouches[0].pageX - rect.left, e.targetTouches[0].pageY - rect.top, false);
            });
            $('#canvas').on('touchmove', (e) => {//pointermove
                if (this.mousePressed) {
                    this.canvasEl = this.canvas.nativeElement;
                    const rect = this.canvasEl.getBoundingClientRect();
                    this.Draw(e.targetTouches[0].pageX - rect.left, e.targetTouches[0].pageY - rect.top, true);
                }
            });
            $('#canvas').on('touchend', (e) => { //pointerleave
                if (this.mousePressed) {
                    this.mousePressed = false;
                    this.cPush();
                }
            });
            $('#canvas').on('mousedown', e => { //pointerenter
                console.log('mousedown');
                console.log(this.canvas);

                this.canvasEl = this.canvas.nativeElement;
                console.log(this.canvasEl);
                const rect = this.canvasEl.getBoundingClientRect();
                console.log(rect)
                this.mousePressed = true;
                console.log(rect)
                this.Draw(e.pageX - rect.left, e.pageY - rect.top, false);
            });
            $('#canvas').on('mousemove', (e) => {//pointermove
                if (this.mousePressed) {
                    this.canvasEl = this.canvas.nativeElement;
                    const rect = this.canvasEl.getBoundingClientRect();
                    this.Draw(e.pageX - rect.left, e.pageY - rect.top, true);
                }
            });
            $('#canvas').on('mouseup', (e) => { //pointerleave
                if (this.mousePressed) {
                    this.mousePressed = false;
                    this.cPush();
                }
            });
            $('#canvas').on('mouseleave', (e) => {
                if (this.mousePressed) {
                    this.mousePressed = false;
                    this.cPush();
                }
            });
        });
    }
    SaveCanvas(dataURI) {
        console.log(this.canvasEl);
        this.canvasEl.toBlob((result) => {
            this.camimageList.push({ 'originalname': $('#txtCaption').val(), 'blob': dataURI, 'canvasblob': result })
            $('.draw-img').hide();
            $('.topbar').show();
            $('#txtCaption').val('');
            this.cPushArray = [];
            this.cStep = -1;
        });
    }

}
