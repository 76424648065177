import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { Config } from '../vir-config';
@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }
    // { username: username, password: password }
    login(username: string, password: string) {
     const url = Config.apiurl + '/login';  
     // const url = 'http://aoi031:2500/login/' + username + '/' + password + '';
      const credentials = {               
                "UserName" : username,        
                "Password" : password       
            }
        return this.http.post<any>(url, credentials)
            .map(user => {
                // login successful if there's a jwt token in the response
                if (user[0]) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                   localStorage.setItem('currentUser', JSON.stringify(user[0]));
                }
                return user;
            });
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}