import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { sidebarService } from './sidebar.service';
import { Config } from '../../vir-config';
import { DialogBarComponent } from '../dialog/dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    menuItems: any;
    imgpath: string;
    logo: string;
    companyName: string;
    constructor(private sidebarService: sidebarService, public dialog: MatDialog,private router: Router) {
        this.imgpath = Config.companyLogoUrl;
    }

    ngOnInit() {
        this.GetMenuItems();
        this.logo = JSON.parse(localStorage.currentUser).CompanyLogoFileName;
        this.companyName = JSON.parse(localStorage.currentUser).CompanyName;
    }
    GetMenuItems() {
        this.sidebarService.getusergrouppages().then((data) => {
            this.menuItems = data;
            this.menuItems = this.menuItems.filter(x => x.IsActive == true && x.IsMenu == true);
            this.menuItems = this.menuItems.sort((val1, val2) => { return val2.OrderBy - val1.OrderBy })
        })
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    logOut() {
        const dialogRef = this.dialog.open(DialogBarComponent, {
            width: '250px',
            data: { username: JSON.parse(localStorage.currentUser).Name, message: 'You will be returned to the login screen?' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == 1)
                this.router.navigate(['/login']);
        });
    }
}
