import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { DialogBarComponent } from './dialog.component';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
	MatButtonModule,
	MatInputModule,
	MatRippleModule,
	MatFormFieldModule,
	MatTooltipModule,
	MatSelectModule,
	MatCheckboxModule,
	MatSnackBarModule,
	MatOptionModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatDialogModule,
} from '@angular/material';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
		TruncateModule,
		NgbModule,
		MatButtonModule,
		MatRippleModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatOptionModule,
		MatTooltipModule,
		MatCheckboxModule,
		MatSnackBarModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatDialogModule,
	],
	providers: [

	],
	declarations: [DialogBarComponent],
	entryComponents: [DialogBarComponent]
})
export class dialogModule { }