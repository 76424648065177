import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DataService } from '../../_services/index';
@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    providers: []
})
export class NotificationComponent implements OnInit {
    notificationlist: any;
    notificationcount: any;
    Querystring: any;
    constructor(
        public router: Router,
        private DataService: DataService) {
        // this.vesssellistService.loadnotification();
    }


    ngOnInit() {
        this.DataService.notificationlist.subscribe(
            data => {
                this.notificationlist = data;
                this.notificationcount = this.notificationlist.Count;
                this.notificationlist = this.notificationlist.List;
            }
        )
        this.loadnotification();
    }

    // redirect to report/checklist
    navigate(obj) {
        console.log(obj);
        this.Querystring = {};
        this.Querystring.notificationid = obj.Id;
        this.router.navigate([obj.Url + '/' + obj.RecordId], { queryParams: this.Querystring });

        if (this.router.url.includes(obj.Url)) {
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
                return false;
            };
        }
    }
    loadnotification() {
       
    }

    //{ { list.Status } }
    
}