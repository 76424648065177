import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Config } from '../vir-config';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
@Injectable()
export class UserdataService implements Resolve<any>
{
    userdata: any[];

    constructor(private http: HttpClient) { }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    }

    getuserdata() {

        const url = Config.apiurl + '/user/' + JSON.parse(localStorage.currentUser).Id + '';
        return new Promise((resolve, reject) => {
            this.http.get(url)
                .subscribe((data: any) => {
                    this.userdata = data;
                    resolve(data);
                }, reject);
        });
    }


}
